import React from 'react';
import styled from 'styled-components';

import Container from '../components/Container';
import Seo from '../components/Seo';
import config from '../config';
import { typography } from '../styles/typography';
import Icon from '../components/Icon';

const Content = styled.div`
  width: 100%;
  height: 100%;

  > div {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    > h1 {
      ${typography.h1};
    }
  }
`;

const ExploreButton = styled.button`
  margin-top: ${config.sizes.spacing / 2}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: 2px solid ${config.colors.text};
  border-radius: 25px;
  padding: ${config.sizes.spacing / 2}px;

  > h5 {
    margin: 0;
    margin-right: ${config.sizes.spacing / 4}px;
    text-decoration: none;
    color: ${config.colors.text};
  }

  &:hover {
    background-color: ${config.colors.text};

    > h5 {
      color: ${config.colors.background};
    }

    > svg {
      fill: ${config.colors.background};
    }
  }
`;

const Index = ({ toggleDrawer }) => (
  <>
    <Seo title={`Home`} />
    <Content>
      <Container verticalPadding={false}>
        <h1>drnt's lab</h1>
        <ExploreButton type="button" onClick={toggleDrawer}>
          <h5>Explore</h5>
          <Icon name="arrowRight" size={15} color="black" />
        </ExploreButton>
      </Container>
    </Content>
  </>
);

export default Index;
